import Row from "./Row";
import BoardInterface from "../../interfaces/BoardInterface";
import {maxRows, State} from "../../constants/Constants";
import './Board.css'
import {range} from "../../App";
import React from "react";
import Button from "../ui/Button";
import TileInterface from "../../interfaces/TileInterface";

interface PropsInterface {
    board: BoardInterface;
    input: string;
    won: boolean;
}

export default function Board(props: PropsInterface) {
    const board = props.board;
    const input = props.input;
    const emptyRows = maxRows - (board.guesses.length + 1);
    const iterable = range(emptyRows);

    return (
        <div className="game">
            <h1 className="heading">Randordle</h1>

            <div className="board">
                {props.won && (
                    <div className="flex-col">
                        <span className="subheader">the word was:</span>
                        <Button text={board.word}/>
                        <span className="again">reload to play again</span>
                    </div>
                )}

                {board.guesses.map((guess: string, key: number) => {
                    const tiles = calculateTiles(guess, board.word, true);

                    return <Row key={key} tiles={tiles}/>;
                })}

                {board.guesses.length < maxRows && (
                    <Row key={maxRows} tiles={calculateTiles(input, board.word, false)}/>
                )}

                {iterable.map((number: number, key: number) => {
                    return <Row key={key} tiles={calculateEmptyTiles(board.letters)}/>;
                })}
            </div>
        </div>
    )
}

function calculateTiles(guess: string, word: string, revealed: boolean): TileInterface[]
{
    let tiles: TileInterface[] = [];
    let map = new Map();

    guess.toLowerCase().split('').forEach((character: string, key: number) => {
        if (!map.get(character)) {
            map.set(character, countCorrectlyPlacedCharacters(character, guess, word));
        }

        let state = State.GRAY;
        const matchWord = word.toLowerCase().match(new RegExp(character, 'g'));

        if (
            word.toLowerCase().includes(character)
            && word.toLowerCase().charAt(key) !== character
            && matchWord !== null
            && matchWord.length > map.get(character)
        ) {
            state = State.YELLOW;
        }

        if (word.toLowerCase().charAt(key) === character) {
            state = State.GREEN;
        }

        map.set(character, map.get(character)+1);
        tiles.push({
            state: state,
            character: character,
            revealed: revealed
        })
    })

    if (tiles.length >= word.length) {
        return tiles;
    }

    const missingTiles = word.length - tiles.length;

    for (let i = 0; i < missingTiles; i++) {
        tiles.push({
            state: null,
            character: null,
            revealed: false
        })
    }

    return tiles;
}

function countCorrectlyPlacedCharacters(character: string, guess: string, word: string): number
{
    let count = 0;

    for (let i = 0; i < word.length; i++) {
        if (
            word.charAt(i).toLowerCase() === character.toLowerCase()
            && word.charAt(i).toLowerCase() === guess.charAt(i).toLowerCase()
        ) {
            count++;
        }
    }

    return count;
}

function calculateEmptyTiles(letters: number): TileInterface[]
{
    let tiles: TileInterface[] = [];

    for(let i = 0; i < letters; i++) {
        tiles.push({
            state: null,
            character: null,
            revealed: false
        })
    }

    return tiles;
}