import Tile from "./Tile";
import TileInterface from "../../interfaces/TileInterface";

interface PropsInterface {
    tiles: TileInterface[];
}

export default function Row(props: PropsInterface) {
    return (
        <div className="row">
            {props.tiles.map((tile: TileInterface, key: number) => (
                <Tile key={key} tile={tile}/>
            ))}
        </div>
    )
}
